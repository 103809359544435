(function($){
	$( document ).ready(function() {
		console.log( "ready!" );
			$('a[href*="#"]').on('click', function(){
				console.log('hashtag clicked and now scrolling');
				$('html, body').animate({
					scrollTop: $( $.attr(this, 'href') ).offset().top
				}, 500);
				return false;
			}); 


	});
})(jQuery); 